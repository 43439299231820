let right = JSON.parse(localStorage.getItem('rights'));

const state = {
    windowWidth: 0,
    shallShowOverlay: false,
    apiUrlAuth: process.env.VUE_APP_AUTH_URL,
    apiBApiUrl: process.env.VUE_APP_APIURL,
    apiBillingiUrl: process.env.VUE_APP_APIURL + '/billing',
    apiCustomerUrl: process.env.VUE_APP_APIURL + '/customer/api/v1',
    apiUserUrl: process.env.VUE_APP_APIURL + '/user',
    apiCampaignUrl: process.env.VUE_APP_APIURL + '/campaign',
    event: process.env.VUE_APP_APIURL + '/event',
    creasUrl: process.env.VUE_APP_APIURL + '/creative',
    apiSupportUrl: process.env.VUE_APP_APIURL + '/support',
    brand: process.env.VUE_APP_BRAND,
    apiBanner: process.env.VUE_APP_APIURL + '/banner',
    apiStatistics: process.env.VUE_APP_APIURL + '/statistics',
    deploy: process.env.VUE_APP_APIURL + '/deploy',
    sqlManager: process.env.VUE_APP_APIURL + '/sql-manager',
    apiImportUrl: process.env.VUE_APP_APIURL + '/import',
    apiBMedia: process.env.VUE_APP_APIURL + '/media',
    mediaMetrics: [],
    potentialCtriterias: [],
    potentialCriteriasTF1: [],
    availableImpressions: 0,
    creativeCamps: [],
    currentCampaignCode: '',
    currentCampaignsList: [],
    currentAlertsList: [],
    globalAlerts: [],
    statusSubFilter: [],
    capitalizedBrands: [],
    brandsMedias: [],
    brandsCampaigns: [],
    brandsAlerts: [],
    capitalizedMedias: [],
    capitalizedMediasAlerts: [],
    campaignsModified: false,
    mediasModified: false,
    currentMediasList: [],
    alertTypes: [],
    groupedAlerts: [],
    globalConfigPageCampaigns: {
        currentPage: 1,
        brandFilter: [],
        mediaFilter: [],
        typeFilter: [],
        searchFilter: '',
        sortBy: 'startDateCampaign',
    },
};

if (right && right.trading && right.trading == 1) {
    state.statusFilter = ['7'];
} else if (right && right.client_service && right.client_service == 1) {
    state.statusFilter = ['8'];
    state.statusSubFilter = ['csm'];
} else if (right && right.direction && right.direction == 1) {
    state.statusFilter = ['8'];
    state.statusSubFilter = ['direction'];
} else {
    state.statusFilter = ['0'];
    state.statusSubFilter = [];
}

export default state;

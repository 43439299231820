const mutations = {
    UPDATE_WINDOW_WIDTH(state, val) {
        state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
        state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
    },

    SET_MEDIA_METRICS(state, val) {
        state.mediaMetrics = val;
    },

    SET_POTENTIAL_CRITERIAS(state, val) {
        state.potentialCtriterias = val;
    },

    SET_POTENTIAL_CRITERIAS_TF1(state, val) {
        state.potentialCriteriasTF1 = val;
    },
    SET_AVAILABLE_IMPRESSIONS(state, val) {
        state.availableImpressions = val;
    },

    SET_CREATIVE_CAMPS(state, val) {
        state.creativeCamps = val;
    },

    UPDATE_CURRENT_CAMPAIGN_CODE(state, val) {
        state.currentCampaignCode = val;
    },

    UPDATE_STATUS_FILTERS(state, val) {
        state.statusFilter = val;
    },

    UPDATE_CAMPAIGNS_LIST(state, val) {
        state.currentCampaignsList = val;
    },

    UPDATE_ALERTS_LIST(state, val) {
        state.currentAlertsList = val;
    },

    UPDATE_GLOBAL_ALERTS(state, val) {
        state.globalAlerts = val;
    },

    UPDATE_CURRENT_PAGE_CAMPAIGNS(state, val) {
        state.globalConfigPageCampaigns.currentPage = val;
    },

    UPDATE_GLOBAL_CONFIG_PAGE_CAMPAIGNS(state, val) {
        state.globalConfigPageCampaigns[val.field] = val.value;
    },

    RESET_GLOBAL_CONFIG_PAGE_CAMPAIGNS(state) {
        state.globalConfigPageCampaigns = {
            currentPage: 1,
            brandFilter: [],
            mediaFilter: [],
            typeFilter: [],
            searchFilter: '',
            sortBy: 'startDateCampaign',
        };
    },

    UPDATE_STATUS_ALERT(state, val) {
        const alert = state.globalAlerts.find((el) => el.code === val.code);
        alert.resolved = val.status;
    },

    UPDATE_MEDIAS_LIST(state, val) {
        state.currentMediasList = val;
    },

    UPDATE_STATUS_SUBFILTER(state, val) {
        state.statusSubFilter = val;
    },

    SET_BRANDS_MEDIAS(state, val) {
        state.brandsMedias = val;
    },

    SET_BRANDS_CAMPAIGNS(state, val) {
        state.brandsCampaigns = val;
    },
    SET_BRANDS_LIST(state, val) {
        state.capitalizedBrands = val;
    },

    SET_BRANDS_LIST_ALERTS(state, val) {
        state.brandsAlerts = val;
    },

    SET_MEDIAS_LIST(state, val) {
        state.capitalizedMedias = val;
    },

    SET_MEDIAS_ALERTS(state, val) {
        state.capitalizedMediasAlerts = val;
    },

    SET_ALERTS_TYPES(state, val) {
        state.alertTypes = val;
    },

    SET_GROUPED_ALERTS(state, val) {
        state.groupedAlerts = val;
    },

    SET_MODIFIED_CAMPAIGNS(state, val) {
        state.campaignsModified = val;
    },

    SET_MODIFIED_MEDIAS(state, val) {
        state.mediasModified = val;
    },
};

export default mutations;

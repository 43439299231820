import Vue from 'vue';
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import GenericConfirmModal from './partials/generic-modal-confirm.vue';

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component('toast', ToastificationContent);
Vue.component('generic-confirm-modal', GenericConfirmModal);

Vue.filter('numberThousand', function (value) {
    if (value > 0) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return 0;
});

Vue.filter('humanizeDate', function (date) {
    return new Intl.DateTimeFormat('fr').format(new Date(date));
});

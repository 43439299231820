import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false


  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };
    this.validityDuration = 1440;
    this.subscribers = [];

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()
        if(config.url === (this.jwtConfig.refreshEndpoint)) return config;

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          config.headers.brand = "adcleek"
        }
        let tokenValidity = this.getTokenValidity();
        if(tokenValidity && new Date().getTime() > new Date(tokenValidity).getTime() + (this.validityDuration / 2)){
          this.refreshToken().then((res) => {
            this.setLoginData(res.data.token, res.data.validity);
          }).catch((e) => {
            console.error(e);

          })
        }

        return config
      },
      error => Promise.reject(error),
    )

    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config
        
        if (response && response.status === 401) {
          let indexOfSubscriber = this.subscribers.findIndex(request => request.url === originalRequest.url);
          if(indexOfSubscriber >= 0) {
            this.subscribers.splice(indexOfSubscriber, 1);
            window.location = window.origin + '/login'
            return Promise.reject(error)
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.subscribers.push(originalRequest);
            this.axiosIns(originalRequest);
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use()
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  getRights() {
    return localStorage.getItem('rights');
  }

  getTokenValidity() {
    return localStorage.getItem('tokenValidity');
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setUserEmail(email) {
    localStorage.setItem('userEmail', email);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  setRights(value) {
    localStorage.setItem('rights', value);
  }

  setLoginData (token, validity, email) {
    this.setToken(token);
    this.setTokenValidity(validity);
    this.setUserEmail(email);
  }

  setTokenValidity(value) {
    this.validityDuration = value;
    let date = new Date();
    date.setSeconds(date.getSeconds() + value);
    localStorage.setItem('tokenValidity', date);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  logout() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem('rights')
    localStorage.removeItem('tokenValidity');

    localStorage.removeItem('userEmail');
    // Remove userData from localStorage
    localStorage.removeItem('userData')

  }

  refreshToken() {
    return this.axiosIns.get(this.jwtConfig.refreshEndpoint, {
      headers: {
        authorization: 'Bearer ' + this.getRefreshToken(),
      }
    })
  }
}

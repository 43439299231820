<template>
    <b-modal
        v-model="show"
        :title="title"
        :cancel-title="cancelTitle"
        :ok-title="okTitle"
        :ok-variant="okVariant"
        :ok-only="okOnly"
        :size="size"
        @ok.prevent="onAccept"
        @cancel.prevent="onCancel"
        :hide-footer="hideFooter"
    >
        <slot></slot>
    </b-modal>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
            cancelTitle: {
                type: String,
                default: 'Non',
            },
            okTitle: {
                type: String,
                default: 'Oui',
            },
            okVariant: {
                type: String,
                default: 'primary',
            },
            closeOnCancel: {
                type: Boolean,
                default: true,
            },
            okOnly: {
                type: Boolean,
                default: false,
            },
            size: {
                type: String,
                default: 'md',
            },
            hideFooter: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                show: false,
            };
        },
        methods: {
            onAccept() {
                this.$emit('on-accept');
            },
            onCancel() {
                this.$emit('on-cancel');
                if (this.closeOnCancel) this.close();
            },
            open() {
                this.show = true;
            },
            close() {
                this.show = false;
            },
        },
    };
</script>

<style></style>

import Vue from 'vue';
import {
    ToastPlugin,
    ModalPlugin,
    LayoutPlugin,
    CardPlugin,
    FormPlugin,
    FormCheckboxPlugin,
    ButtonPlugin,
    TablePlugin,
    OverlayPlugin,
    BadgePlugin,
    DropdownPlugin,
    FormInputPlugin,
    SkeletonPlugin,
    FormSelectPlugin,
    AlertPlugin,
    FormGroupPlugin,
    InputGroupPlugin,
    FormFilePlugin,
    TabsPlugin,
    FormTextareaPlugin,
    FormDatepickerPlugin,
    PopoverPlugin,
    PaginationPlugin,
    SpinnerPlugin,
} from 'bootstrap-vue';
import wysiwyg from 'vue-wysiwyg';
import VueCompositionAPI from '@vue/composition-api';

import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(LayoutPlugin);
Vue.use(CardPlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(ButtonPlugin);
Vue.use(TablePlugin);
Vue.use(OverlayPlugin);
Vue.use(PaginationPlugin);
Vue.use(FormInputPlugin);
Vue.use(BadgePlugin);
Vue.use(DropdownPlugin);
Vue.use(AlertPlugin);
Vue.use(FormSelectPlugin);
Vue.use(SkeletonPlugin);
Vue.use(FormGroupPlugin);
Vue.use(InputGroupPlugin);
Vue.use(FormFilePlugin);
Vue.use(TabsPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(PopoverPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(SpinnerPlugin);

Vue.use(wysiwyg, {});

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

import 'vue-wysiwyg/dist/vueWysiwyg.css';

const app = new Vue({
    router,
    store,
    methods: {
        toggleGlobalLoading(val) {
            const appLoading = document.getElementById('loading-bg');
            appLoading.style.display = val ? 'block' : 'none';
        },
    },
    render: (h) => h(App),
}).$mount('#app');

window.app = app;

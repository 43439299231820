import Vue from 'vue';
import VueRouter from 'vue-router';
import useJwt from '@/auth/jwt/useJwt';

Vue.use(VueRouter);

const isUserLoggedIn = () => {
    return localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName);
};
const router = new VueRouter(
    {
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        };
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Home/index.vue'),
            meta: {
                pageTitle: 'Tableau de bord',
                breadcrumb: [
                    {
                        text: 'Gestion des campagnes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sms',
            name: 'sms',
            component: () => import('@/views/SMSManager/index.vue'),
            meta: {
                pageTitle: 'Gestion des SMS',
                breadcrumb: [
                    {
                        text: 'Gestion des SMS',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/templates-parameter',
            name: 'templates-parameter',
            component: () => import('@/views/Templates/index.vue'),
            meta: {
                pageTitle: 'Paramétrer un template',
                breadcrumb: [
                    {
                        text: 'Paramétrer un template',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/associate-creative',
            name: 'associate-creative',
            component: () => import('@/views/ManageCampaigns/AssociateCreatives/index.vue'),
            meta: {
                pageTitle: 'Associer un film à une campagne',
                breadcrumb: [
                    {
                        text: 'Associer un film',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/trading/:campaign_code',
            name: 'trading',
            component: () => import('@/views/Trading/index.vue'),
            meta: {
                pageTitle: 'trading',
                breadcrumb: [
                    {
                        text: 'Modifier campagne',
                        active: true,
                    },
                ],
            },
        },
        {
            // path: '/details/:campaign_code',
            path: '/details',
            name: 'details',
            component: () => import('@/views/Home/Details/DetailsCampaign.vue'),
            meta: {
                pageTitle: 'Campagnes',
                breadcrumb: [
                    {
                        text: 'Détail de campagne',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/link-campaigns',
            name: 'link-campaigns',
            component: () => import('@/views/LinkCampaigns/index.vue'),
            meta: {
                pageTitle: 'Lier des campagnes',
                breadcrumb: [
                    {
                        text: 'Lier des campagnes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/manage-metrics',
            name: 'manage-metrics',
            component: () => import('@/views/ManageMetrics/index.vue'),
            meta: {
                pageTitle: 'Gestion des metrics',
                breadcrumb: [
                    {
                        text: 'Gestion des metrics',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/listing-auth',
            name: 'listing-auth',
            component: () => import('@/views/ListingAuth/index.vue'),
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Utilisateurs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/listing-pos',
            name: 'listing-pos',
            component: () => import('@/views/ListingPos/index.vue'),
            meta: {
                pageTitle: 'Gestion des points de vente',
                breadcrumb: [
                    {
                        text: 'Points de vente',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/listing-zipcode',
            name: 'listing-zipcode',
            component: () => import('@/views/ListingZipcode/index.vue'),
            meta: {
                pageTitle: 'Gestion des codes postaux',
                breadcrumb: [
                    {
                        text: 'Codes postaux',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/listing-invoices',
            name: 'listing-invoices',
            component: () => import('@/views/ListingInvoices/index.vue'),
            meta: {
                pageTitle: 'Gestion des factures',
                breadcrumb: [
                    {
                        text: 'Factures',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/import-data',
            name: 'import-data',
            component: () => import('@/views/ImportData/index.vue'),
            meta: {
                pageTitle: 'Gestion de MAJ',
                breadcrumb: [
                    {
                        text: 'MAJ',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/second-page',
            name: 'second-page',
            component: () => import('@/views/SecondPage.vue'),
            meta: {
                pageTitle: 'Second Page',
                breadcrumb: [
                    {
                        text: 'Second Page',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/banner-management',
            name: 'banner-management',
            component: () => import('@/views/BannerManagement/index.vue'),
            meta: {
                pageTitle: 'Gérer les bannières',
                breadcrumb: [
                    {
                        text: 'Gérer les bannières',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/banner-brand-management',
            name: 'banner-brand-management',
            component: () => import('@/views/ManageBrand/index.vue'),
            meta: {
                pageTitle: 'Gestion des marques',
                breadcrumb: [
                    {
                        text: 'Gestion des marques',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/bug-management',
            name: 'bug-management',
            component: () => import('@/views/BugManager/index.vue'),
            meta: {
                pageTitle: 'Gestion des bugs',
                breadcrumb: [
                    {
                        text: 'Gestion des bugs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/e-dealer',
            name: 'e-dealer',
            component: () => import('@/views/EDealer/index.vue'),
            meta: {
                pageTitle: 'Gestion E-Dealer',
                breadcrumb: [
                    {
                        text: 'Gestion E-Dealer',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/logout',
            name: 'logout',
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
        {
            path: '/france-tv',
            name: 'france-tv',
            component: () => import('@/views/FranceTV/index.vue'),
            meta: {
                pageTitle: 'Potentiel france TV',
                breadcrumb: [
                    {
                        text: 'Potentiel france TV',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/deployment',
            name: 'projet-deployment',
            component: () => import('@/views/ProjectDeploy/index.vue'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Déployer un projet',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/pmr',
            name: 'pmr',
            component: () => import('@/views/PmrCampaign/index.vue'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Créer des campagnes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/creative',
            name: 'ftv-creatives',
            component: () => import('@/views/Creatives/index.vue'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Chercher une creative',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/prices',
            name: 'prices',
            component: () => import('@/views/Prices/index.vue'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Télécharger les prix FTV pour une marque pour (1 Jour)',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/tf1',
            name: 'tf1',
            component: () => import('@/views/TF1/index.vue'),
            meta: {
                pageTitle: 'Potentiel TF1',
                breadcrumb: [
                    {
                        text: 'Potentiel TF1',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/events',
            name: 'events',
            component: () => import('@/views/EventManager/index.vue'),
            meta: {
                pageTitle: 'Manage events',
                breadcrumb: [
                    {
                        text: 'Gestion des évenements',
                    }
                ],
            },
        },
        {
            path: '/sql-manager',
            name: 'sql-manager',
            component: () => import('@/views/Sql_manager/index.vue'),
            meta: {
                breadcrumb: [
                    {
                        text: 'Requete Manager',
                        active: true,
                    },
                ],
            },
        },
    ]
});

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn();
    // Redirect to login if not logged in
    if ((!isLoggedIn && to.name !== 'login') || to.name === 'logout') {
        useJwt.logout();
        return next({
            name: 'login',
        });
    }

    // si on viens d'ouvrir l'application la vue précédente est null
    if (_.name === null && to.name !== 'loading' && to.name !== 'login') {
        return next({
            name: 'loading',
        });
    }
    return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
});

export default router;
